import request from '@/utils/request'
const preFixUrl = '/push'
//process.env.NODE_ENV === 'development' ? "/zcp9018Api/mealOrder" : '/fft/mealOrder'

/**
 * 订单管理 -- 获取订单列表
 * @param {*} data
 * @returns
 */
export const orderList = (data) => {
  return request({
    url: `${preFixUrl}/orderManage/queryOrderManageInfo`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
/**
 * 积分管理 -- 订单详情
 * @param {*} data
 * @returns
 */
export const queryOrderManageInfoByNum = (data) => {
  return request({
    url: `${preFixUrl}/orderManage/queryOrderManageInfoByNum`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const updateRemark = (data) => {
  return request({
    url: `${preFixUrl}/orderManage/updateRemark`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const exportOrder = (data) => {
  return request({
    url: `${preFixUrl}/orderManage/exportOrder`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    responseType: 'blob',
    data,
  })
}

/**
 * 结算管理 -- 结算列表
 * @param {*} data
 * @returns
 */
export const settlementList = (data) => {
  return request({
    url: `${preFixUrl}/SettlementOrder/GetSettlementOrder`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
/**
 * 结算管理 -- 结算明细列表
 * @param {*} data
 * @returns
 */
export const settlementOrderDetailList = (data) => {
  return request({
    url: `${preFixUrl}/SettlementOrder/GetSettlementOrderDetail`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
/**
 * 结算管理 -- 结算订单
 * @param {*} data
 * @returns
 */
export const settlementOrder = (data) => {
  return request({
    url: `${preFixUrl}/SettlementOrder/SettlementOrder`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}

export const getTYImageUrl = (data) => {
  return request({
    url: `${preFixUrl}/imageOss/getTYImageUrl`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
