<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <el-page-header @back="goBack" :content="title + '商品'">
        </el-page-header>
      </div>
      <el-form
        :inline="true"
        :model="submitForm"
        :rules="submitRules"
        ref="searchRef"
        label-width="120px"
      >
        <div v-show="pageNum == 0">
          <el-form-item label="商品主图（比例1：1）" prop="giftName">
            <el-upload
              :limit="9"
              :action="action"
              :headers="headers"
              :on-remove="handleRemove"
              :before-upload="beforeAvatarUpload"
              name="photos"
              :on-success="handleChangeUpload"
              :on-exceed="exceedUpload"
              accept="image/*"
              list-type="picture-card"
              :file-list="fileList"
              :class="[uploadDisabled2 ? 'disabled' : '', 'upload-main']"
              multiple
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <br />
          <el-form-item label="商品名称" prop="goodsName">
            <el-input
              v-model="submitForm.goodsName"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="副标题" prop="subtitle">
            <el-input
              v-model="submitForm.subtitle"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item label="商品有效期至" prop="effectiveTime">
            <el-date-picker
              v-model="submitForm.effectiveTime"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="排序" prop="sort">
            <el-input
              v-model="submitForm.sort"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item label="商品状态" prop="isState">
            <el-switch
              v-model="submitForm.isState"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </el-form-item>
          <br />
          <el-form-item label="购买须知" prop="buyNotice">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入内容"
              v-model="submitForm.buyNotice"
            >
            </el-input>
          </el-form-item>
          <br />
          <div class="dif">
            <el-form-item label="商品类别" prop="goodsType">
              <el-radio
                v-model="submitForm.goodsType"
                @change="goodsTypeChange"
                label="1"
                >服务</el-radio
              >
              <el-radio
                v-model="submitForm.goodsType"
                @change="goodsTypeChange"
                label="2"
                >套餐</el-radio
              >
            </el-form-item>
          </div>
          <br />
          <div class="foot">
            <div class="foot_left" v-if="submitForm.goodsType == 1">
              <div
                style="
                  display: flex;
                  border: 1px solid;
                  padding-top: 20px;
                  width: 500px;
                  margin-bottom: 10px;
                "
                v-for="(item, index) in submitForm.goodsInfoList"
                :key="index"
              >
                <div>
                  <el-form-item label="服务名称" prop="sort">
                    <el-input
                      v-model="item.goodsServeName"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="服务次数" prop="sort">
                    <el-input
                      v-model="item.goodsServeCount"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </div>
                <div style="margin-right: 20px">
                  <el-button
                    v-if="submitForm.goodsInfoList.length > 1"
                    type="primary"
                    style="height: 100px"
                    @click="delgoodsInfoList(item)"
                    >删除</el-button
                  >
                </div>
              </div>
              <el-button
                style="margin-top: 20px"
                type="primary"
                @click="
                  submitForm.goodsInfoList.push({
                    goodsServeName: '',
                    goodsServeCount: '',
                  })
                "
                >新增</el-button
              >
            </div>
            <div class="foot_left" v-if="submitForm.goodsType == 2">
              <div
                style="
                  display: flex;
                  border: 1px solid;
                  padding-top: 20px;
                  width: 500px;
                  margin-bottom: 10px;
                "
                v-for="(item, index) in submitForm.goodsInfoList"
                :key="index"
              >
                <div>
                  <el-form-item label="服务名称" prop="sort">
                    <el-input
                      v-model="item.goodsServeName"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="服务次数" prop="sort">
                    <el-input
                      v-model="item.goodsServeCount"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <br />
          <el-form-item label="设置价格区间" prop="giftMaxNumber">
            <el-switch
              v-model="submitForm.priceRange"
              active-value="1"
              inactive-value="0"
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </el-form-item>
          <br />
          <el-form-item label="商品详情" prop="introduction">
            <div ref="editor" id="editor" class="editor"></div>
          </el-form-item>
          <br />
          <el-button type="primary" @click="baocunone" :loading="saveLoading"
            >保存下一步</el-button
          >
          <el-button
            type="primary"
            @click="wugyigechange"
            :loading="saveLoading"
            >保存无规格</el-button
          >
        </div>
        <!-- <el-form-item label="商品规格" prop="giftMaxNumber">
          <div style="display: flex">
            <el-input
              v-model="submitForm.specName"
              placeholder="规格名称"
              clearable
            ></el-input
            >：
            <el-input
              v-model="submitForm.specValue"
              placeholder="规格数值"
              clearable
            ></el-input>
          </div>
        </el-form-item>
        <br />
        <el-form-item label="原价" prop="originalPrice">
          <el-input
            v-model="submitForm.originalPrice"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item label="售价" prop="sellingPrice">
          <el-input
            v-model="submitForm.sellingPrice"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item label="起售量" prop="sellingValue">
          <el-input
            v-model="submitForm.sellingValue"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item label="展示图" prop="showImage">
          <el-upload
            :limit="5"
            :headers="headers"
            :action="action"
            :on-remove="handleRemove2"
            :before-upload="beforeAvatarUpload"
            name="photos"
            :on-success="handleChangeUpload2"
            :on-exceed="exceedUpload2"
            accept="image/*"
            list-type="picture-card"
            :file-list="fileList2"
            :class="[uploadDisabled3 ? 'disabled' : '', 'upload-main']"
            multiple
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item> -->
        <div v-if="pageNum == 1">
          <el-form-item label="商品规格" prop="giftMaxNumber">
            <el-button
              type="primary"
              @click="
                submitForm.specInfoList.push({
                  specName: '',
                  specValue: '',
                  originalPrice: '',
                  sellingPrice: '',
                  sellingPrice: '',
                  showImage: '',
                  showImageUrl: '',
                })
              "
              :loading="saveLoading"
              >新增</el-button
            >
            <div
              style="display: flex; margin-top: 10px"
              v-for="(item, index) in submitForm.specInfoList"
              :key="index"
            >
              <el-input
                v-model="item.specName"
                placeholder="规格名称"
                clearable
              ></el-input
              >：
              <el-input
                v-model="item.specValue"
                placeholder="规格数值"
                clearable
              ></el-input>
              <div style="margin-right: 20px">
                <el-button
                  v-if="submitForm.specInfoList.length > 1"
                  type="primary"
                  style="margin-left: 20px"
                  @click="delspecInfoList(item)"
                  >删除</el-button
                >
              </div>
            </div>
          </el-form-item>
          <br />
          <el-button type="primary" @click="pageNum = 0" :loading="saveLoading"
            >上一步</el-button
          >
          <el-button type="primary" @click="xiayibutow" :loading="saveLoading"
            >保存下一步</el-button
          >
        </div>
        <div class="container" v-if="pageNum == 2">
          <!-- <el-button type="primary">同步价格</el-button>
        <el-button type="primary">同步库存</el-button> -->
          <div class="table">
            <el-table :data="submitForm.specInfoList" style="width: 100%">
              <el-table-column
                v-if="wugyige == 0"
                label="规格名称"
                prop="specName"
              >
              </el-table-column>
              <el-table-column
                v-if="wugyige == 0"
                label="规格值"
                prop="specValue"
              >
              </el-table-column>
              <el-table-column label="* 原价">
                <template slot-scope="scope">
                  <el-input-number
                    v-model="scope.row.originalPrice"
                    :precision="2"
                    placeholder="请输入"
                    :min="0.01"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="* 售价">
                <template slot-scope="scope">
                  <el-input-number
                    v-model="scope.row.sellingPrice"
                    :precision="2"
                    :step="0.1"
                    placeholder="请输入"
                    :min="0.01"
                    :max="1000000000"
                  ></el-input-number>
                </template>
              </el-table-column>

              <el-table-column label="* 起售量">
                <template slot-scope="scope">
                  <el-input-number
                    v-model="scope.row.sellingValue"
                    placeholder="请输入"
                    :step="1"
                    :min="1"
                    :max="10000"
                  ></el-input-number>
                </template>
              </el-table-column>
              <!-- <el-table-column label="库存预警值">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.stockAlertNum"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </template>
            </el-table-column> -->
              <el-table-column label="展示图" width="100px" align="center">
                <template slot-scope="scope">
                  <el-upload
                    class="avatar-uploader1"
                    :action="action"
                    name="photos"
                    :headers="headers"
                    :before-upload="beforeAvatarUpload"
                    :on-success="
                      (response, file, fileList) =>
                        handleChangeUpload2(response, file, fileList, scope)
                    "
                    :show-file-list="false"
                    accept="image/*"
                    :multiple="false"
                  >
                    <img
                      v-if="scope.row.showImageUrl"
                      :src="scope.row.showImageUrl"
                      class="avatar1"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon1"></i>
                  </el-upload>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-button @click="togo()">上一步</el-button>
          <el-button type="primary" @click="submit()" :loading="saveLoading"
            >完成</el-button
          >
        </div>
      </el-form>
      <div class="btnright"></div>
    </el-card>
  </div>
</template>
<script>
import wangeditor from 'wangeditor'
import * as orderApi from '@/api/order'
export default {
  data() {
    return {
      radio: '1',
      submitForm: {
        goodsInfoList: [{}],
      },
      submitRules: {
        goodsName: [{ required: true, message: '请输入商品名称' }],
        sort: [{ required: true, message: '请输入排序' }],
        isState: [{ required: true, message: '请选择商品状态' }],
        goodsType: [{ required: true, message: '请选择商品类别' }],
        originalPrice: [{ required: true, message: '请输入商品原价' }],
        sellingPrice: [{ required: true, message: '请输入商品售价' }],
        sellingValue: [{ required: true, message: '请输入商品起售量' }],
        effectiveTime: [{ required: true, message: '请填写有效期' }],
      },

      tableData: [],
      newTable: [],
      nowtable: [],
      currentPages: 1,
      pageSizes: 4,
      totals: null,
      activityLoading: false,
      delarr: [],
      ItemGroup: [],
      temporaryId: null,
      //商品
      ShopgoodsDialog: false,
      tableShop: [],
      currentPage: 1,
      pageSize: 4,
      total: null,
      shopArr: [],
      shownum: 1,
      ispush: true,
      saveLoading: false,
      ceshi: [
        [14, 98, 111],
        [14, 98, 117],
      ],
      action: 'https://yluser.hzty-pension.cn/lifeServe/push/imageOss/tyUpload',
      fileList: [],
      newImgList: [],
      newImgList2: [],
      ruleForm: {
        coverImage: '',
        coverImage2: '',
      },
      headers: {
        Authorization: sessionStorage.getItem('Authorization'),
      },
      submitForm: {
        goodsName: '',
        subtitle: '',
        effectiveTime: '',
        sort: '',
        isState: '0',
        goodsType: '1',
        goodsInfo: '',
        goodsInfoList: [
          {
            goodsServeName: '',
            goodsServeCount: '',
          },
        ],
        priceRange: '0',
        introduction: '',
        specName: '',
        specValue: '',
        originalPrice: '',
        sellingPrice: '',
        sellingValue: '',
        showImage: '',

        introduction: '',
        specInfoState: 1,
        specInfo: '',

        specInfoList: [
          {
            specName: '',
            specValue: '',
            originalPrice: 0,
            sellingPrice: 0,
            sellingValue: 1,
            showImage: '',
            showImageUrl: '',
          },
        ],
      },
      title: '新增',
      fileList2: [],
      keyarr: [],
      skuHeader: [{}],
      pageNum: 0,
      wugyige: 0,
    }
  },
  filters: {
    objarr(arr) {
      if (arr) {
        let obj = []
        JSON.parse(arr).forEach((item) => {
          obj.push(Object.values(item))
        })
        return obj.toString()
      } else {
        return ''
      }
    },
  },
  created() {
    this.title = '新增'
  },
  mounted() {
    this.temporaryId = this.$route.query.id
    if (this.$route.query.data) {
      console.log(1)
      let data = JSON.parse(this.$route.query.data)
      this.submitForm = JSON.parse(JSON.stringify(data))
      let goodsInfoList = JSON.parse(JSON.stringify(data.typeList))
      let specList = JSON.parse(JSON.stringify(data.specList))
      this.$set(this.submitForm, 'goodsInfoList', goodsInfoList)
      this.$set(this.submitForm, 'specInfoList', specList)
      this.title = '修改'
      this.$nextTick(() => {
        this.setWangEditor()
        this.$nextTick(() => {
          this.editor.txt.html(this.submitForm.introduction)
        })
      })
      let images = []
      if (this.submitForm.imagesListUrl?.length) {
        this.submitForm.imagesListUrl.map((item) => {
          this.fileList.push({
            key: item.coverImageKey,
            name: item.coverImageKey,
            url: item.coverImageOssUrl,
          })
          images.push(item.coverImageKey)
          this.newImgList = this.fileList
        })
        this.submitForm.images = images.join(',')
        this.ruleForm.coverImage = this.submitForm.images
      }
      let showImage = []
      if (this.submitForm.showImagesListUrl?.length) {
        this.submitForm.showImagesListUrl.map((item) => {
          this.fileList2.push({
            key: item.showImageKey,
            name: item.showImageKey,
            url: item.showImageOssUrl,
          })
          showImage.push(item.showImageKey)
          this.newImgList2 = this.fileList2
        })
        this.submitForm.showImage = showImage.join(',')
        this.ruleForm.coverImage2 = this.submitForm.showImage
      }
      // if (this.submitForm.coverImage) {
      //   let coverImageList = this.submitForm.coverImage.split(',')
      //   coverImageList.map((item) => {
      //     orderApi
      //       .getTYImageUrl({
      //         data: item,
      //       })
      //       .then((res) => {
      //         this.fileList = [
      //           {
      //             key: item,
      //             name: item,
      //             url: res.data,
      //           },
      //         ]
      //       })
      //   })
      //   // this.newImgList = this.fileList
      //   this.ruleForm.coverImage = this.submitForm.coverImage
      // }
      // if (this.submitForm.showImage) {
      //   let imgList = this.submitForm.showImage.split(',')
      //   console.log(imgList, 'imgList')
      //   imgList.map((item) => {
      //     // this.
      //     orderApi
      //       .getTYImageUrl({
      //         data: item,
      //       })
      //       .then((res) => {
      //         this.fileList2.push({
      //           key: item,
      //           name: item,
      //           url: res.data,
      //         })
      //       })
      //   })
      // }
      // this.newImgList2 = this.fileList2
      // this.ruleForm.showImage = this.submitForm.showImage
    } else {
      console.log(2)
      this.title = '新增'
      this.$nextTick(() => {
        this.setWangEditor()
      })
    }
  },
  computed: {
    uploadDisabled2() {
      return this.newImgList.length >= 9
    },
    uploadDisabled3() {
      return this.newImgList2.length >= 9
    },
  },
  watch: {
    radio(newVal, oldVal) {
      if (newVal == 1) {
        this.tableData = []
        this.shownum = 1
      }
      if (newVal == 2) {
        this.submitForm.group = []
      }
    },
  },
  methods: {
    togo() {
      if (this.wugyige == 1) {
        this.pageNum = 0
        this.wugyige = 0
      } else {
        this.pageNum = 0
      }
    },
    wugyigechange() {
      if (
        !this.submitForm.goodsName ||
        !this.submitForm.sort ||
        // !this.submitForm.isState ||
        !this.submitForm.goodsType

        //  ||
        // !this.submitForm.effectiveTime
      ) {
        console.log(
          !this.submitForm.goodsName,
          !this.submitForm.sort,
          // !this.submitForm.isState,
          !this.submitForm.goodsType
        )
        this.saveLoading = false
        return this.$message({
          type: 'error',
          message: '不能为空!',
        })
      }
      if (!this.submitForm.effectiveTime) {
        return this.$message({
          type: 'error',
          message: '商品有效期不能为空!',
        })
      }
      this.pageNum = 2
      this.wugyige = 1
    },
    baocunone() {
      if (
        !this.submitForm.goodsName ||
        !this.submitForm.sort ||
        // !this.submitForm.isState ||
        !this.submitForm.goodsType
        //  ||
        // !this.submitForm.effectiveTime
      ) {
        console.log(
          !this.submitForm.goodsName,
          !this.submitForm.sort,
          // !this.submitForm.isState,
          !this.submitForm.goodsType
        )
        this.saveLoading = false
        return this.$message({
          type: 'error',
          message: '不能为空!',
        })
      }
      if (!this.submitForm.effectiveTime) {
        return this.$message({
          type: 'error',
          message: '商品有效期不能为空!',
        })
      }
      this.pageNum = 1
    },
    xiayibutow() {
      const i = this.submitForm.specInfoList.findIndex((item) => !item.specName)
      const j = this.submitForm.specInfoList.findIndex(
        (item) => !item.specValue
      )
      console.log(i, j)
      if (i == -1 && j == -1) {
        this.pageNum = 2
      } else {
        this.$message.error('请输入规格名称和规格值！')
      }
    },
    goodsTypeChange() {
      console.log(111)
      this.submitForm.goodsInfoList = [
        {
          goodsServeName: '',
          goodsServeCount: '',
        },
      ]
    },
    setWangEditor() {
      let that = this
      this.editor = new wangeditor(this.$refs.editor)
      // 关闭粘贴样式的过滤
      this.editor.config.pasteFilterStyle = false
      //设置placeholder 的提示文字
      this.editor.config.placeholder = '请输入内容'
      //设置zindex
      this.editor.config.zIndex = 1
      //设置编辑区域高度
      this.editor.config.height = 500
      // 自定义配置颜色（字体颜色、背景色）
      this.editor.config.colors = [
        '#000000',
        '#404040',
        '#888888',
        '#b2b2b2',
        '#d6d6d6',
        '#c24f4a',
        '#8baa4a',
        '#7b5ba1',
        '#46acc8',
        '#f9963b',
        '#ff0000',
        '#ffffff',
      ]
      //设置菜单
      this.editor.config.menus = [
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'list',
        'justify',
        'quote',
        'image',
        'table',
        'splitLine',
        'undo',
        'redo',
      ]
      this.editor.config.fontNames = [
        '宋体',
        '微软雅黑',
        'Arial',
        'Tahoma',
        'Verdana',
        'Helvetica',
        'sans-serif',
      ]
      //使用本地图片上传
      this.editor.config.uploadImgMaxSize = 10 * 1024 * 1024 // 5M
      this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        resultFiles.forEach(async (item) => {
          console.log('item: ', item)
          const { data, key } = await that.$store.dispatch(
            'upload/setUploadFile',
            item
          )
          console.log('data: ', data)
          console.log('data: ', key)
          that.keyarr.push(key)
          console.log(that.keyarr, 'that.keyarr')
          try {
            if (data) {
              that.$message.success('上传成功', 'success')
              // 上传图片，返回结果，将图片插入到编辑器中
              await insertImgFn(data)
              // let str = that.editor.txt.html()
              //将img替换为[#key#]
              // let newContent = str.replace(
              //   /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
              //   `[#${key}#]`
              // )
              //
            }
          } catch (error) {
            that.$message.error('上传失败', 'error')
          }
        })
      }
      this.editor.config.showLinkImg = false
      this.editor.config.showFullScreen = false
      this.editor.create()
    },
    getKey(val) {
      // return (
      //   (val.split('ctyun.cn:443/')[1] &&
      //     val.split('ctyun.cn:443/')[1].split('?')[0]) ||
      //   (val.split('ctyun.cn/')[1] &&
      //     val.split('ctyun.cn/')[1].split('?')[0]) ||
      //   null
      // )
      return (
        (val.split('ctyun.cn:443/')[1] &&
          val.split('ctyun.cn:443/')[1].split('?')[0]) ||
        val.split('ctyun.cn/')[1].split('?')[0]
      )
    },
    handleRemove(file, fileList) {
      this.ruleForm.coverImage = ''
      this.newImgList = this.newImgList.filter((item) => {
        return item.uid != file.uid
      })
      this.ruleForm.coverImage = this.newImgList
        .map((item) => item.key)
        .join(',')
    },
    handleRemove2(file, fileList) {
      this.ruleForm.coverImage2 = ''
      this.newImgList2 = this.newImgList2.filter((item) => {
        return item.uid != file.uid
      })
      this.ruleForm.coverImage2 = this.newImgList2
        .map((item) => item.key)
        .join(',')
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 jpg,png,jpeg 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 10MB!')
      }
      return isJPG && isLt2M
    },
    handleChangeUpload(response, file, fileList) {
      this.fileList = fileList
      this.newImgList = fileList.map((item) => {
        if (
          (item.status =
            'success' || (item.response && item.response.code == 200))
        ) {
          return {
            uid: item.uid,
            name:
              (item.response && item.response.data[0].imageKey) || item.name,
            url: (item.response && item.response.data[0].imageVa) || item.url,
            key: (item.response && item.response.data[0].imageKey) || item.key,
          }
        }
      })
      this.ruleForm.coverImage = this.newImgList
        .map((item) => item.key)
        .join(',')
    },
    handleChangeUpload2(response, file, fileList, scope) {
      this.newImgList2 = fileList.map((item) => {
        if (item.response && item.response.code == 200) {
          scope.row.showImageUrl = item.response.data[0].imageVa
          scope.row.showImage = item.response.data[0].imageKey
        }
      })
    },
    // handleChangeUpload2(response, file, fileList) {
    //   this.newImgList2 = fileList.map((item) => {
    //     if (
    //       (item.status =
    //         'success' || (item.response && item.response.code == 200))
    //     ) {
    //       return {
    //         uid: item.uid,
    //         name:
    //           (item.response && item.response.data[0].imageKey) || item.name,
    //         url: (item.response && item.response.data[0].imageVa) || item.url,
    //         key: (item.response && item.response.data[0].imageKey) || item.key,
    //       }
    //     }
    //   })
    //   this.ruleForm.coverImage2 = this.newImgList2
    //     .map((item) => item.key)
    //     .join(',')
    // },
    //超出上传限制
    exceedUpload(file, fileList) {
      this.$message.error('超出上传最多限制9！')
    },
    exceedUpload2(file, fileList) {
      this.$message.error('超出上传最多限制5！')
    },
    numberFn(p) {
      this.submitForm[p] =
        this.submitForm[p]
          .replace(/[^\d^\.]+/g, '')
          .replace(/^0+(\d)/, '$1')
          .replace(/^\./, '0.')
          .match(/^\d*(\.?\d{0,2})/g)[0] || ''
    },
    // 在 Input 值改变时触发
    handleEdit(e) {
      let value = e.replace(/^(0+)|[^\d]+/g, '') // 以0开头或者输入非数字，会被替换成空
      value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
      this.submitForm.giftNumber = value
    },
    handleEdits(e) {
      let value = e.replace(/^(0+)|[^\d]+/g, '') // 以0开头或者输入非数字，会被替换成空
      value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
      this.submitForm.giftMaxNumber = value
    },
    handShop(arr) {
      this.shopArr = arr
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getShop()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getShop()
    },
    async getShop() {
      let res = await this.$base.postAjax(
        '/seckill/ActivityProducts/getActivityS',
        {
          code: '',
          data: {
            categoryName: '', //商品类目
            groupName: '', //商品分组
            name: '', //商品名称
            temId: this.temporaryId,
          },
          page: this.currentPage,
          pageSize: this.pageSize,
          time: '',
        }
      )
      if (res.data.code == 200) {
        this.tableShop = res.data.data.data
        this.total = res.data.data.total
      }
    },
    add() {
      this.getShop()
      this.ShopgoodsDialog = true
    },
    closeShop() {
      this.ShopgoodsDialog = false
      this.reset(1)
    },
    reset(n) {
      if (n == 1) {
        this.$refs.multipleTable.clearSelection()
        this.currentPage = 1
        this.pageSize = 4
      }
      if (n == 2) {
        this.$refs.multipleTables.clearSelection()
        this.currentPages = 1
        this.pageSizes = 4
      }
    },
    async addShop() {
      if (this.shopArr.length == 0) {
        return this.$message({
          type: 'error',
          message: '请选择商品',
        })
      }
      if (this.shownum > 1 && this.tableData.length) {
        this.shopArr.map((item) => {
          this.tableData.map((e) => {
            if (item.spu == e.spu) {
              this.ispush = false
              return this.$message({
                type: 'error',
                message: '不要选择重复商品',
              })
            }
          })
        })

        if (this.ispush) {
          this.tableData.push(...this.shopArr)
        }
      } else {
        this.tableData = this.shopArr
      }
      this.ShopgoodsDialog = false
      this.shownum++
      this.reset(1)
      this.initialtable()
    },
    handleSizeChanges(val) {
      this.pageSizes = val
      this.initialtable()
    },
    handleCurrentChanges(val) {
      this.currentPages = val
      this.initialtable()
    },
    // 获取到选中的
    handleSelectionChange(e) {
      this.delarr = e
    },
    initialtable() {
      this.newTable = this.tableNewlist(this.tableData)
      this.nowtable = this.newTable[this.currentPages - 1]
      this.totals = this.tableData.length
    },
    tableNewlist(arr) {
      let newList = []
      for (var i = 0; i < arr.length; i += this.pageSizes) {
        newList.push(arr.slice(i, i + this.pageSizes))
      }
      return newList
    },

    goBack() {
      this.$router.push({
        path: '/shop',
        query: {
          // pageN: this.currentPage,
          // id: res.data.data,
        },
      })
    },
    async submit() {
      // if (!this.submitForm.specName && !this.submitForm.specValue) {
      //   return this.$message({
      //     type: 'error',
      //     message: '商品规格不能为空!',
      //   })
      // }
      // console.log(this.newImgList2, this.ruleForm,'=======>')
      const that = this
      let str = this.editor.txt.html()
      // console.log(str, 'str')
      console.log('str: ', str)
      let newContent = str
      // str.replace(
      //   /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
      //   function (match, capture) {
      //     if (that.getKey(capture)) {
      //       return '<img ' + 'src=' + '"' + that.getKey(match) + '"' + '/>'
      //     } else {
      //       return match
      //     }
      //   }
      // )
      // console.log('newContent: ', newContent)
      if (this.wugyige == 0) {
        this.submitForm.specInfoState = 1
      } else {
        this.submitForm.specInfoState = 0
      }
      this.submitForm.introduction = newContent
      // this.editData.introductionImage =

      //   this.keyarr.map((item) => item).join(',') || this.edittab.introductionImage
      // this.saveLoading = true

      // if (this.radio == 1) {
      //   if (this.submitForm.group.length == 0) {
      //     this.saveLoading = false
      //     return this.$message({
      //       type: 'error',
      //       message: '分组不能为空!',
      //     })
      //   }
      // }
      // if (this.radio == 2) {
      //   if (this.tableData.length == 0) {
      //     this.saveLoading = false
      //     return this.$message({
      //       type: 'error',
      //       message: '活动商品不能为空!',
      //     })
      //   }
      // }

      // let money = this.tPrice(this.submitForm.giftPrice)
      // console.log(this.ruleForm.coverImage2, this.ruleForm.coverImage1)
      // return
      let data = {
        // code: 'string',
        // data: {
        // coverImage,
        // images,
        // goodsName,
        // subtitle: '',
        // effectiveTime,
        // sort,
        // isState,
        // goodsType,
        // goodsInfo,
        // priceRange,
        // introduction,
        // specName,
        // specValue,
        // originalPrice,
        // sellingValue,
        // showImage,
        ...this.submitForm,
        goodsInfo: JSON.stringify(this.submitForm.goodsInfoList),
        showImage: this.ruleForm.coverImage2,
        coverImage: this.newImgList[0].key || '',
        images: this.ruleForm.coverImage,
        specInfo: JSON.stringify(this.submitForm.specInfoList),
        // introduction: this.editData.introduction,
        // },
        // time: 'string',
      }
      // if (this.radio == 1) {
      //   data.data.groups = this.submitForm.group.map((item) => {
      //     return item.toString().replace(/,/g, '_') //.replace(/\s+/g, '-').toLowerCase()
      //   })
      // }
      // if (this.radio == 2) {
      //   data.data.spus = this.tableData.map((item) => {
      //     return item.spu
      //   })
      // }
      if (this.title == '新增') {
        let res = await this.$base.postAjax(
          '/push/merchandiseControl/addLifeServeGoods',
          data
        )
        if (res.data.code == 200) {
          this.$message({
            type: 'success',
            message: '保存成功!',
          })
          this.goBack()
          this.saveLoading = false
        } else {
          this.saveLoading = false
        }
      } else {
        let resd = await this.$base.postAjax(
          '/push/merchandiseControl/updateLifeServeGoods',
          data
        )
        if (resd.data.code == 200) {
          this.$message({
            type: 'success',
            message: '保存成功!',
          })
          this.goBack()
          this.saveLoading = false
        } else {
          this.saveLoading = false
        }
      }

      this.saveLoading = false
    },
    delgoodsInfoList(item) {
      console.log(item)
      this.submitForm.goodsInfoList.splice(
        this.submitForm.goodsInfoList.indexOf(item),
        1
      )
    },
    delspecInfoList(item) {
      this.submitForm.specInfoList.splice(
        this.submitForm.specInfoList.indexOf(item),
        1
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.dif {
  display: flex;
  justify-content: space-between;
}
.foot {
}
.btnright {
  text-align: left;
}
.form-box {
  margin-bottom: 20px;
}
.data-list {
  .el-icon-picture-outline {
    font-size: 20px;
  }
}
::v-deep.table-selection {
  visibility: hidden;
}
::v-deep.avatar1 {
  width: 55px;
  height: 55px;
  display: block;
}
</style>
